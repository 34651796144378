import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { ObjectType } from "../types/common";
export let api: {
  [key: string]: any;
};

const useUrlComp = () => {
  const { userData } = useContext(AuthContext);

  const live = {
    verify: "https://verify-service.apps.microservices.apxxup.lagosride.com",

    feeSetup: "https://fee-service.apps.microservices.apxxup.lagosride.com/v2",
    //  *
    // areas: "https://staging-server.lagosride.com/area-service2/v2",
    // #
    oems: "https://vmr-service.apps.microservices.apxxup.lagosride.com/v2",
    users: "https://user-service.apps.microservices.apxxup.lagosride.com/v2",
    vehicles: "https://vehicle-service.apps.microservices.apxxup.lagosride.com/v2",
    revenueSplit: "https://lrapi-nginx.lagosride.com/revenue-service2/v2",
    setting: "https://settings-service.apps.microservices.apxxup.lagosride.com/v2",

    wallet: "https://wallet-service.apps.microservices.apxxup.lagosride.com/v2",
    fileUpload: "https://fileupload.apps.microservices.apxxup.lagosride.com/v2",
    trips: "https://lrapi-nginx.lagosride.com/trip-reporting2/v2",
    schedules: "https://schedule-service.apps.microservices.apxxup.lagosride.com/v2",
  };
  const staging = {
    // verify: "https://staging-server.lagosride.com/verify-service2/v2",
    verify: "https://staging-server.lagosride.com/verify-service2",
    feeSetup: "https://staging-server.lagosride.com/fee-service2/v2",
    // areas: "https://staging-server.lagosride.com/area-service2/v2",
    users: "https://staging-server.lagosride.com/user-service2/v2",
    vehicles: "https://staging-server.lagosride.com/vehicle-service2/v2",
    revenueSplit: "https://staging-server.lagosride.com/revenue-service2/v2",
    setting: "https://staging-server.lagosride.com/setting-service2/v2",
    oems: "https://staging-server.lagosride.com/oem-service2/v2",
    wallet: "https://staging-server.lagosride.com/wallet-service2/v2",
    fileUpload: "https://staging-server.lagosride.com/fileupload-service2/v2",
    trips: "https://staging-server.lagosride.com/trip-reporting2/v2",
    schedules: "https://staging-server.lagosride.com/schedule-service2/v2",
    messages: "https://staging-server.lagosride.com/message-service2/v2",

    // users: "https://staging-server.lagosride.com/user-service2/v2"
  };
  api = userData?.data_mode === "live" ? live : staging;
  return null;
};

export default useUrlComp;
