import { FunctionComponent, ReactNode } from "react";
import { ObjectType } from "../../types/common";

type Props = {
  children: ReactNode;
  className?: string;
  style?: ObjectType;
};

const CusCard = ({ className, children, style }: Props) => {
  return (
    <div className={`rounded ${className}`} style={{ backgroundColor: "rgba(255, 255, 255, 1)", ...style}}>
      {children}
    </div>
  );
};

export default CusCard;
