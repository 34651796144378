import { Id, ToastContainer } from "react-toastify";
import RoutesComponent from "./routes/router";

import axios from "axios";
import { QueryClient, QueryClientProvider } from "react-query";
import AllModals from "./components/modals/allModals";
import AppPrefrenceContextProvider from "./contexts/AppPrefrenceContext";
import AuthContextProvider from "./contexts/AuthContext";
import LoadingContextProvider from "./contexts/LoadingContext";
import TripsContextProvider from "./contexts/tripsContext";
import { toast } from "react-toastify";
import { useEffect } from "react";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // Set enabled to false globally for all queries
    },
  },
});

function App() {
  (function () {
    const token = localStorage.getItem("access_token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
  })();
  let id: Id;

  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        if (config.url) {
          const url = new URL(config.url);
          const searchParams = new URLSearchParams(url.search);
          const component = searchParams.get("component");
          if (component === "export") {
            id = toast.loading("Exporting Data");
          }
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    axios.interceptors.response.use(
      (response) => {
        if (response.data.export) {
          toast.update(id, {
            render: "Data Exported Successfully",
            type: "success",
            isLoading: false,
            autoClose: 2000,
          });
        }
        if (response.data.status === "error") {
          toast.dismiss(id);
        }
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.replace("/signin");
        }
        // return Promise.reject(error);
      }
    );
  });

  return (
    <div className={"App"}>
      <ToastContainer position={"top-right"} />
      <QueryClientProvider client={queryClient}>
        <AppPrefrenceContextProvider>
          <AuthContextProvider>
            <LoadingContextProvider>
              <TripsContextProvider>
                <AllModals />
                <RoutesComponent />
              </TripsContextProvider>
            </LoadingContextProvider>
          </AuthContextProvider>
        </AppPrefrenceContextProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
