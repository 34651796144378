import React from 'react'

type Props = {}

const SubTitle = ({ text }: { text: string }) => (
    <div className="mb-4">
      <small>{text}</small>
    </div>
  );

export default SubTitle