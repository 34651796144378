import {
  Dispatch,
  Fragment,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { useQuery } from 'react-query';
import { Modal, ModalBody, Table } from 'reactstrap';
import EmptyPage from '../../../components/EmptyPage';
import NewFilter from '../../../components/filter/NewFilter';
import ExportComponent from '../../../components/shared/exportComponent';
import FilterComponent from '../../../components/shared/filterComponent';
import GradientButton from '../../../components/shared/gradidentButton';
import WaitingLoader from '../../../components/shared/waitingLoader';
import PaginationComponent from '../../../components/tablesComponents/paginationComponent';
import SeearchComponent from '../../../components/tablesComponents/searchComponent';
import TableDataCounter from '../../../components/tablesComponents/tableDataCounter';
import queryKeys from '../../../constants/queryKeys';
import { AppPrefrenceContext } from '../../../contexts/AppPrefrenceContext';
import { calculatePostDay } from '../../../helpers/dateTimeHelper';
import { usePaginationHelper } from '../../../helpers/paginationHelper';
import { useQueryStringHelper } from '../../../helpers/queryStringHelper';
import { useUsersApiService } from '../../../services/usersService';
import { promoCodeType } from '../old-finance/revenue/data';
import { getCodeTypeFromPromoType } from '../users/helpers/strings';
import PromoDetails from './components/promoDetails';
import PromoModalBody from './components/promoModalBody';
import { ObjectType, ReactChangeEventType } from '../../../types/common';
import { getEndDate, getStartDate } from './data/data';

type Props = {
  setActiveTab: Dispatch<SetStateAction<string>>;
  setPromoType: Dispatch<SetStateAction<string>>;
  activeTab: string;
  promoType: string;
};
const PromoLog = (props: Props) => {
  const { activeTab, promoType } = props;
  const [toggled, setToggled] = useState(false);
  const [startDate, setStartDate] = useState(getStartDate(promoType));
  const [modalOpen, setModalOpen] = useState(false);
  const [endDate, setEndDate] = useState(getEndDate(promoType));
  const [data, setData] = useState<ObjectType | []>([]);
  const [promoId, setPromoId] = useState('');
  const [promoDetails, setPromoDetails] = useState([]);
  const [codeType, setCodeType] = useState('');
  const { getPageNoFromQueryString, getQueryStringValue } =
    useQueryStringHelper();
  const [currentPage, setCurrentPage] = useState(getPageNoFromQueryString());
  const [keyword, setKeyword] = useState('');
  const { getPromoCodesToast } = useUsersApiService();
  const { setBreadCrumbData } = useContext(AppPrefrenceContext);
  const [filterState, setFilterState] = useState({
    startDate,
    endDate,
    codeType,
  });

  useEffect(() => {
    setBreadCrumbData(['Promo', `${activeTab}`]);
  }, [activeTab]);

  const { paginate } = usePaginationHelper();
  const onPaginate = (pageNumber: number) => {
    paginate({
      pageNumber,
      setCurrentPage,
      // callbackFunction: null,
    });
  };

  const handleToggle = () => {
    setToggled(!toggled);
  };
  const handleModalToggle = () => {
    setModalOpen((prev) => !prev);
  };

  const { isLoading, data: promo } = useQuery(
    [queryKeys.promo, filterState],
    () =>
      getPromoCodesToast({
        component: '',
        start_date: startDate,
        end_date: endDate,
        code_type: codeType || getCodeTypeFromPromoType(promoType),
      }),
    {
      select: (data) => data?.data || [],
      // enabled: false,
      keepPreviousData: true,
    }
  );
  const { data: promoCount } = useQuery(
    [queryKeys.promoCount, filterState],
    () =>
      getPromoCodesToast({
        component: 'count',
        start_date: startDate,
        end_date: endDate,
        code_type: codeType || getCodeTypeFromPromoType(promoType),
      }),
    {
      select: (data) => data?.data?.total || 0,
      // enabled: false,
      keepPreviousData: true,
    }
  );

  const handleChange = (e: ReactChangeEventType) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const filterOptions = [
    {
      title: 'Promo Type',
      options: promoCodeType,
      currentValue: codeType,
      onChange: setCodeType,
      name: 'promoType',
    },
  ];

  const onApply = () => {
    setFilterState({ startDate, endDate, codeType });
    handleToggle();
  };

  if (isLoading && !promo) {
    return <WaitingLoader />;
  } else if (promoId) {
    return (
      <div>
        <PromoDetails
          promoId={getQueryStringValue('promo-id') || promoId}
          promoDetails={promoDetails}
          setPromoId={setPromoId}
        />
      </div>
    );
  }
  return (
    <>
      <div className='d-flex justify-content-between text-nowrap flex-wrap'>
        <div className='d-flex align-items-center'>
          <div className=''>
            <div className='d-flex align-items-center  '>
              <div className='fw-bolder fs-5 capitalize'>
                {promoType} {activeTab}
              </div>
            </div>
          </div>
          <div className='ms-4'>
            {<SeearchComponent keyword={keyword} setKeyword={setKeyword} />}
          </div>
        </div>
        <div className='d-flex align-items-center'>
          <div className='me-3'>{promo?.length > 0 && <ExportComponent />}</div>

          <div className='me-3'>
            <NewFilter
              direction={'start'}
              toggleComponent={<FilterComponent />}
              toggled={toggled}
              handleToggle={handleToggle}
              options={filterOptions}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              onApply={onApply}
            />
          </div>
          <div style={{ width: 120 }}>
            <GradientButton
              text='Create Promo'
              className='p-2'
              onClick={() => {
                setModalOpen(true);
              }}
            />
          </div>
        </div>
      </div>

      <div className='mt-2'>
        {promo?.length === 0 && <EmptyPage text='No Promo' />}
        {promo?.length > 0 && (
          <Table responsive className='table-hover'>
            <thead className=''>
              <tr>
                <th className=''>Promo Code</th>
                <th className=''>Code Type</th>
                <th className=''>Code Owner</th>
                <th className=''>User Limit</th>
                <th className=''>Discount Value</th>
                <th className=''>Start Date</th>
                <th className=''>End Date</th>
              </tr>
            </thead>

            <tbody>
              {promo?.map((item: any) => (
                <Fragment key={item?._id}>
                  <tr
                    className='cursor-pointer capitalize'
                    onClick={() => {
                      setPromoId(item?.promo_id);
                      setPromoDetails(item);
                    }}
                  >
                    <td>{item?.code || 'N|A'}</td>
                    <td>{item?.promo_code_owner || 'N|A'}</td>
                    <td>{item?.code_type || 'N|A'}</td>
                    <td>{item?.users_limit || 0}</td>
                    <td>
                      {item?.discount_type === 'amount'
                        ? `₦${Math.floor(
                            item?.discount_value || 0
                          ).toLocaleString()}`
                        : `${item?.discount_value || 0}%`}
                    </td>
                    <td>{calculatePostDay(item?.start_date)}</td>
                    <td>{calculatePostDay(item?.expiry_date)}</td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </Table>
        )}
        {promo?.length > 0 && (
          <div className='d-flex justify-content-between align-items-center mt-4 flex-wrap'>
            <div className='mt-2'>
              <TableDataCounter
                item={promo}
                currentPage={currentPage}
                totalCount={promoCount}
              />
            </div>
            <div className='mt-2'>
              <PaginationComponent
                activePage={currentPage}
                itemsCountPerPage={50}
                totalItemsCount={promoCount}
                onChange={onPaginate}
              />
            </div>
          </div>
        )}
      </div>
      <Modal
        size='lg'
        isOpen={modalOpen}
        toggle={handleModalToggle}
        backdrop='static'
        // style={{ width: 400 }}
      >
        <ModalBody className='p-4'>
          <div className='d-flex justify-content-between'>
            <div className='fw-bold'>Create Promo</div>
            <AiOutlineClose size={20} onClick={handleModalToggle} />
          </div>
          <PromoModalBody
            handleChange={handleChange}
            handleToggle={handleModalToggle}
            promoDetails={promoDetails}
            data={data}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default PromoLog;
