import { Button, Card, CardTitle, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label } from "reactstrap";
import { getDateLabelFromDates } from "../../Pages/others/users/helpers/strings";
import GradientButton from "../shared/gradidentButton";
import { useState } from "react";
const FilterOptions = ({ toggled, handleToggle, selectedOption, optionChange, options, name, toggleComponent, topComponent, bottomComponent, width, direction, startDate, endDate }: any) => {
  const [tempOption, setTempOption] = useState(selectedOption);
  const handleClick = () => {
    console.log("fgssrdf");
    optionChange(tempOption);
    handleToggle();
  };

  return (
    <Dropdown direction={direction || "down"} className="d-inline" isOpen={toggled} toggle={handleToggle}>
      <DropdownToggle caret={false} className="btn-sm p-0 text-muted border-white " style={{ backgroundColor: "white" }}>
        {/* {toggleComponent ? toggleComponent : selectedOption.slice(0, 13)} */}
        {toggleComponent ? (
          <div onClick={handleToggle}>{toggleComponent}</div>
        ) : (
          <div>
            {options?.find((item: any) => item?.value === selectedOption)?.label.slice(0, 13)} {startDate !== undefined ? getDateLabelFromDates(startDate, endDate, selectedOption) : ""}
            <span className="ms-1">
              <svg width="10" height="6" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.3002 0.5L4.58353 2.78772C4.2627 3.05789 3.7377 3.05789 3.41686 2.78772L0.700195 0.5" stroke="#04A7A7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </span>
          </div>
        )}
      </DropdownToggle>
      <DropdownMenu className="px-3" style={{ width: width ? width : 200 }}>
        <div className="mb-3">
          <small className="fw-bold ms-4 ">Show for</small>
        </div>
        {topComponent && topComponent}
        {options?.map((item: any) => (
          <div>
            <FormGroup check className="mb-3" key={item.value}>
              <Input id={name + item.value} name={name} type="radio" value={item.value} onChange={(e) => setTempOption(e)} defaultChecked={selectedOption === item.value} />
              <Label check className={`${selectedOption === item.value && "gradient-text"}`} for={name + item.value}>
                {item.label}
              </Label>
            </FormGroup>
            {item?.subOptionComponent && selectedOption === item.value && (
              <Card className="position-fixed p-2 px-3" style={{ width: 220, top: 20, left: "90%" }}>
                {item.subOptionComponent}
              </Card>
            )}
          </div>
        ))}
        {bottomComponent && bottomComponent}
        <div className="w-full p-2 mt-3">
          {/* <Button className="btn-sm w-full gradient-btn ms-3 mt-2 px-3" onClick={handleToggle}>Apply</Button> */}
          <GradientButton className="py-1" text="APPLY" onClick={handleClick} />
          {/* <Button className="btn-sm ms-3 mt-2 px-3">Clear</Button>  */}
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default FilterOptions;
