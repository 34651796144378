import { ReactNode } from "react";
import "../../assets/scss/auth.scss";

type Props = {
  children: ReactNode;
  title: string;
};
const AuthLayout = ({ children, title }: Props) => {
  return (
    <div className="d-flex justify-content-center h-100 align-items-center auth-layout">
      <div className="">
        <div className="main-content text-white">
          {/* <div className="fw-bold title">{title}</div> */}
          {children}
        </div>
      </div>
    </div>
  );
};
export default AuthLayout;
