import { BrowserRouter, Route, Routes } from "react-router-dom";
import StandardLayout from "../components/layouts/StandardLayout";
import AuthMiddleware from "../middleware/AuthMiddleware";
import { userRoutes } from "../routes/routesList";

const ProtectedPages = () => {
  return (
    <Routes>
      {/* {userRoutes.map((route, idx) => (
        <Route key={idx} path={route.path} element={<AuthMiddleware  isAuthProtected pageTitle={route.title} component={route.component} layout={StandardLayout} />} />
      ))} */}

      {userRoutes.map((route) => {
        return !route?.children ? (
          <Route key={route.path} path={route.path} element={<AuthMiddleware pageTitle={route.title} component={route.component} layout={StandardLayout} isAuthProtected />} />
        ) : (
          <Route key={route.path} path={route.path}>
            {route.children.map((item) => (
              <Route key={item.path} path={route.path + item.path} element={<AuthMiddleware pageTitle={item.title} component={item.component} layout={StandardLayout} isAuthProtected />} />
            ))}
          </Route>
        );
      })}
    </Routes>
  );
};

export default ProtectedPages;
