import { ReactNode, createContext, useEffect, useState } from "react";
import { ObjectType } from "../types/common";

interface MyContextType {
  userData?: ObjectType;
  loginUserAction: any;
  logoutUserAction: (data: any) => void;
}

// export const AuthContext = createContext<MyContextType | undefined>({});
export const AuthContext = createContext<any>({});

const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [userData, setUserData] = useState<ObjectType>({});

  useEffect(() => {
    const getObjectFromLocalStorage = () => {
      const userDataLs = localStorage.getItem("user_data");
      if (userDataLs) {
        try {
          const parsedObject = JSON.parse(userDataLs);
          setUserData(parsedObject);
        } catch (error) {
          //  console.error("Error parsing object from local storage:", error);
        }
      }
    };
    // Call the function to retrieve object from local storage
    getObjectFromLocalStorage();
  }, []);
  const loginUserAction = (data: ObjectType) => {
    localStorage.setItem("access_token", data?.token);
    localStorage.setItem("auth_id", data?.auth_id);
    localStorage.setItem("user_data", JSON.stringify(data));
  };
  const logoutUserAction = () => {
    localStorage.clear();
    setUserData({});
  };
  return <AuthContext.Provider value={{ userData, loginUserAction, logoutUserAction }}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
