import "bootstrap/dist/css/bootstrap.min.css";
import * as React from "react";
import * as ReactDOM from "react-dom";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";

// TODO: move scss to individual pages
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./assets/scss/custom.scss";
import "./assets/scss/index.scss";

// import "./assets/scss/auth.scss";
// import "./assets/scss/business.scss";
// import "./assets/scss/pages.scss";

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById("root")
// );

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(() => {});
