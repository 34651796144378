import { lazy } from "react";
const Wallet = lazy(() => import("../../Pages/others/finance/wallet"));
const Revenue = lazy(() => import("../../Pages/others/finance/revenue"));
const Disbursement = lazy(() => import("../../Pages/others/finance/disbursement"));
const ServicePayment = lazy(() => import("../../Pages/others/finance/servicePayment"));

export default {
  path: "/finance",
  title: "Finance",
  children: [
    {
      path: "/wallet",
      component: Wallet,
      title: "Wallet",
      permission: "Wallet",
    },
    {
      path: "/revenue",
      component: Revenue,
      title: "Revenue",
      permission: "Revenue",
    },
    {
      path: "/disbursement",
      component: Disbursement,
      title: "Disbursement",
      permission: "Disbursement",
    },
    {
      path: "/service-payment",
      component: ServicePayment,
      title: "Service Payment",
      permission: "Service Payment",
    },
  ],
};
