import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import LoaderPage from '../../../../components/LoaderPage.tsx';
import NewFilter from '../../../../components/filter/NewFilter.tsx';
import FilterComponent from '../../../../components/shared/filterComponent.tsx';
import PaginationComponent from '../../../../components/tablesComponents/paginationComponent.tsx';
import queryKeys from '../../../../constants/queryKeys.tsx';
import { AppPrefrenceContext } from '../../../../contexts/AppPrefrenceContext';
import { usePaginationHelper } from '../../../../helpers/paginationHelper.tsx';
import { useQueryStringHelper } from '../../../../helpers/queryStringHelper.tsx';
import { useTripsApiService } from '../../../../services/tripsApiService.tsx';
import { ObjectType } from '../../../../types/common.tsx';
import OneColumn from './components/OneColumn.tsx';
import RevenuePerformance from './components/categories/revenuePerformance.tsx';
import TripPerformance from './components/categories/tripPerformance';
import VehiclePerformance from './components/categories/vehiclePeerformance.tsx';
import Title from './components/title';
import ExportComponent from '../../../../components/shared/exportComponent.tsx';

const Overview = () => {
  const { getPageNoFromQueryString } = useQueryStringHelper();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const { setBreadCrumbData } = useContext(AppPrefrenceContext);
  const { getTripsPerformanceToast } = useTripsApiService();
  const [currentPage, setCurrentPage] = useState(getPageNoFromQueryString());

  const [filterState, setFilterState] = useState({
    startDate,
    endDate,
  });

  const { paginate } = usePaginationHelper();

  const onPaginate = (pageNumber: number) => {
    paginate({
      pageNumber,
      setCurrentPage,
    });
  };

  const [toggled, setToggled] = useState(false);
  const handleToggle = () => {
    setToggled(!toggled);
  };
  useEffect(() => {
    setBreadCrumbData(['Operation', 'Overview']);
  }, []);

  const {
    isLoading: countLoading,
    // isError: countIsError,
    // error: countErro,
    data: tripOperationsPerformance,
  } = useQuery(
    [queryKeys.tripOperationsPerformance, filterState],
    () =>
      getTripsPerformanceToast({
        request_type: 'trip-performance',
        page: currentPage,
        start_date: startDate,
        end_date: endDate,
      }),
    {
      select: (data) => data?.data,
    }
  );
  const {
    // isLoading: countLoading,
    // isError: countIsError,
    // error: countErro,
    data: tripOperationsPerformanceCount,
  } = useQuery(
    [queryKeys.tripOperationsPerformanceCount, filterState],
    () =>
      getTripsPerformanceToast({
        request_type: 'trip-performance',
        component: 'count',
        page: currentPage,
        start_date: startDate,
        end_date: endDate,
      }),
    {
      select: (data) => data?.data?.total || 0,
    }
  );

  const { refetch: exportTripPerformance } = useQuery(
    [queryKeys.tripPerformanceExport, currentPage, filterState],
    () =>
      getTripsPerformanceToast({
        request_type: 'trip-performance',
        component: 'export',
        page: currentPage,
        start_date: startDate,
        end_date: endDate,
      }),
    {
      select: (data) => data?.data?.total || 0,
      keepPreviousData: true,
      enabled: false,
    }
  );

  const onApply = () => {
    setFilterState({ startDate, endDate });
    handleToggle();
  };

  const getItems = (key: string) =>
    tripOperationsPerformance?.map((item: ObjectType) => item[key]);

  if (countLoading) {
    return <LoaderPage />;
  }

  return (
    <div>
      <div className='d-flex align-items-center justify-content-end py-3 me-4'>
        <div className=''>
          <NewFilter
            direction={'start'}
            toggleComponent={<FilterComponent />}
            toggled={toggled}
            handleToggle={handleToggle}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate} 
            setEndDate={setEndDate}
            onApply={onApply}
          />{' '}
        </div>
        <div className=''>
          {tripOperationsPerformance?.length > 0 && (
            <ExportComponent onClick={exportTripPerformance} />
          )}
        </div>
      </div>
      <div className='d-flex mt-3'>
        <div style={{ width: '13%', minWidth: 150 }}>
          <div className='px-3'>
            <Title text='Calender' />
            <OneColumn subTitle={'Date'} items={getItems('group_date')} />
          </div>
        </div>
        <div
          className='me-2 perf-overflow'
          style={{ width: '87%', overflowX: 'scroll' }}
        >
          <div className='d-flex'>
            <TripPerformance
              tripOperationsPerformance={tripOperationsPerformance}
            />
            <VehiclePerformance
              tripOperationsPerformance={tripOperationsPerformance}
            />
            <RevenuePerformance
              tripOperationsPerformance={tripOperationsPerformance}
            />
          </div>
          {/* <Average />
          <Total /> */}
        </div>
      </div>
      <div className='mt-2 d-flex justify-content-end'>
        <PaginationComponent
          activePage={currentPage}
          itemsCountPerPage={50}
          totalItemsCount={tripOperationsPerformanceCount}
          onChange={onPaginate}
        />
      </div>
    </div>
  );
};

export default Overview;
